export const XWAY_RUTUBE_CHANNEL = 'https://rutube.ru/channel/26216756/'

export const OFFER = process.env.VUE_APP_CDN_XWAY + '/docs/Public_Offer.pdf'
export const PRIVACY = process.env.VUE_APP_CDN_XWAY + '/docs/privacy.pdf'
export const SUBSCRIPTION_CONSENT = process.env.VUE_APP_CDN_XWAY + '/docs/Subscription_Consent.pdf'
export const STORAGE_CREDENTIALS_AGREEMENT = process.env.VUE_APP_CDN_XWAY + '/docs/Storage_Credentials_Agreement.pdf'
export const REMUNERATION_AGREEMENT = process.env.VUE_APP_CDN_XWAY + '/docs/Remuneration_Agreement.pdf'

export const HELP_DOCS = {
  SELF_REDEMPTION: {
    name: 'Отметить заказы как "самовыкуп" и для чего это нужно',
    link: 'https://plastic-walker-0c5.notion.site/f463ba0bff854f24af470eb20fc8fea4'
  }
}
