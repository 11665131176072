<template>
  <div class="alert-bar">
    <div class="alert-bar__icon-block">
      <svg-icon
        name="info"
        class-name="alert-bar__icon-block__icon"
      />
    </div>
    <div class="alert-bar__text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertBar'
}
</script>

<style lang="stylus" scoped>
.alert-bar
  display flex
  padding 8px
  background $colors.primary-lightest-2
  border-radius: 4px;
  margin-top 4px
  &__icon-block
    color $colors.accent
    width 16px
    height 16px
    &__icon
      width 16px
      height 16px
  &__text
    margin-left 12px
</style>
