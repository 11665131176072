<template>
  <div :class="cardClasses">
    <div class="head-card__content">
      <div class="head-card__top">
        <div class="head-card__content__left">
          <h4 class="head-card__content__title">
            {{ tariff ? tariff.tariff.name : title }}
          </h4>
          <p class="head-card__content__desc">
            {{ desc }}
          </p>
          <alert-bar v-if="alertBar">
            {{ alertBar }}
          </alert-bar>
        </div>
        <div class="head-card__content__right">
          <img
            :src="imageSrc"
            class="head-card__content__image"
            alt="start"
          >
        </div>
      </div>
      <div class="head-card__content__buttons">
        <x-btn
          v-for="(btn, i) in buttons"
          :key="i"
          v-bind="btn.props"
          @click="btn.action && btn.action()"
          @click.native="btn.native"
        >
          {{ btn.text }}
        </x-btn>
      </div>
    </div>
  </div>
</template>

<script>
import AlertBar from '@/components/Onboarding/AlertBar'
export default {
  name: 'HeadCard',
  components: { AlertBar },
  props: {
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    alertBar: {
      type: String,
      required: false,
      default: ''
    },
    buttons: {
      type: Array,
      default: () => []
    },
    mini: {
      type: Boolean,
      required: false,
      default: false
    },
    tariff: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  computed: {
    cardClasses () {
      const classes = ['head-card']
      if (this.mini) {
        classes.push('head-card--mini')
      }
      return classes
    },
    imageSrc () {
      return require(`@/assets/images/onboarding/headcards/${this.image}`)
    }
  }
}
</script>

<style lang="stylus" scoped>
.head-card
  background $colors.white
  height 213px
  box-shadow $box-shadows.tile
  border-radius 8px

  &__top {
    display flex
    justify-content space-between
  }

  &--mini
    .head-card__content
      &__image
        width 60px
  &__content
    display flex
    flex-direction column
    height 100%
    padding 24px
    &__left
      display flex
      flex-direction column
    &__right
      margin-left 12px
    &__title
      font-size 20px
      font-weight 700
    &__desc
      margin-top 4px
      font-size 14px
      color $colors.gray-darker
    &__buttons
      margin-top auto
    &__image
      width 96px

</style>
