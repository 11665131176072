<template>
  <x-card class="tariff-details-card">
    <h3 class="headline-5 mb-1">
      Ваш тариф «{{ currentTariffName }}»
    </h3>
    <h3 class="text mb-1">
      Кол-во доступных магазинов:
      <span v-if="typeof shopsLimit === 'number'">{{ shopsLimit }}</span>
      <span v-else>не ограничено</span>
    </h3>
    <h2
      v-if="daysText"
      class="headline-3 mb-2"
    >
      {{ daysText }}
    </h2>
    <p
      v-if="nextBill.amount"
      class="d-flex align-center mb-10"
    >
      <span class="gray-dark--text mr-4">Сумма след. списания:</span>
      <span class="mr-1">{{ (+nextBill.amount).toLocaleString('ru') }} ₽</span>
    </p>
    <div
      v-if="unpaid"
      class="mb-4 warning--text"
    >
      Нам не удалось списать стоимость тарифа с вашей карты. Пополните, пожалуйста, баланс карты, или привяжите другую карту, чтобы сохранить подписку и не потерять свои данные.
    </div>
    <div
      v-else-if="tariffChanged"
      class="mb-4 warning--text"
    >
      Вы переключились на тариф <strong>{{ futureTariffName }}</strong>.<br>
      Изменение произойдет после окончания действующей подписки {{ subscriptionExpiresDate }}
    </div>
    <div class="tariff-details-card__controls">
      <x-btn
        v-if="typeof changeAction === 'function'"
        ghost
        @click="changeAction()"
      >
        Сменить тариф
      </x-btn>
    </div>
  </x-card>
</template>

<script>
import createCountFormatter from '@/utils/count-formatter'
import { createNamespacedHelpers } from 'vuex'
import { TARIFF } from '@/constants/tariffs'

const { mapGetters } = createNamespacedHelpers('profile')

export default {
  name: 'TariffDetailsCard',

  props: {
    changeAction: Function
  },

  data: () => ({
    isCardPaymentDisabled: false
  }),

  computed: {
    ...mapGetters(['currentTariff', 'futureTariff', 'nextBill']),

    unpaid () {
      return this.currentTariff?.tariff?.key === TARIFF.TEMPORARY.key
    },

    tariffChanged () {
      return this.currentTariff.tariff?.id !== this.futureTariff?.id
    },

    subscriptionExpiresDate () {
      return new Date(this.currentTariff.expires_at).toLocaleDateString()
    },

    currentTariffName () {
      return this.currentTariff?.tariff?.name
    },

    futureTariffName () {
      return this.futureTariff?.name
    },

    tariffId () {
      return this.currentTariff?.tariff?.id
    },

    shopsLimit () {
      return this.currentTariff?.tariff?.shops_limit
    },

    daysLeft () {
      return this.currentTariff?.days_left
    },

    term () {
      return this.currentTariff?.term
    },

    daysText () {
      if (this.nextBill.in_days) {
        return `Следующее списание через: ${this.nextBill.in_days} ${this.formatText(this.nextBill.in_days)}`
      }

      if (this.daysLeft) {
        return `${this.formatText(this.daysLeft, { few: 'Осталось', one: 'Остался', two: 'Осталось' })}: ${this.daysLeft} ${this.formatText(this.daysLeft)}`
      }

      return ''
    }
  },

  methods: {
    formatText (number, words = { few: 'дней', one: 'день', two: 'дня' }) {
      const formatSecondsCount = createCountFormatter(words)
      return formatSecondsCount(number)
    }
  }
}
</script>

<style lang="stylus" scoped>
.tariff-details-card
  height 100%
  display flex
  flex-direction column

  &__controls
    display flex
    margin-top auto
    justify-content flex-end

.pay-btn
  &--hidden
    visibility hidden
    pointer-events none
</style>
