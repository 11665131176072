<template>
  <x-page>
    <div
      v-if="isCardsListVisible"
      class="row"
    >
      <div class="col col-l-6 col-xxl-4">
        <tariff-details-card :change-action="gotToTariffsPage" />
      </div>
      <div
        v-for="(card, i) in headCards"
        :key="i"
        class="col col-l-3 col-xxl-2 top-card"
      >
        <HeadCard
          class="top-card"
          :title="card.title"
          :desc="card.desc"
          :image="card.image"
          :mini="card.mini"
          :alert-bar="card.alertBar"
          :buttons="card.buttons"
        />
      </div>
    </div>
    <x-tabs
      class="mt-8"
      size="l"
      :tabs="tabs"
    />
  </x-page>
</template>

<script>
import HeadCard from '@/components/Onboarding/HeadCard'
import { getCookie, removeCookie } from '@/utils/cookies'
import { XWAY_RUTUBE_CHANNEL } from '@/constants/links'
import currentTheme from '@/plugins/currentTheme'
import { getDefaultThemeName } from '@xway-team/theme-detector'
import TariffDetailsCard from '@/views/Tariffs/TariffDetailsCard'
import ROUTES from '@/router/ROUTES'

export default {
  name: 'Index',

  components: { TariffDetailsCard, HeadCard },

  data () {
    return {
      headCards: [
        {
          title: 'Наш Telegram-канал',
          desc: 'Новости, аналитика, обновления',
          image: 'telegram.png',
          mini: true,
          buttons: [
            {
              text: 'Открыть',
              native: this.onTelegramOpen,
              props: {
                href: 'https://t.me/xwaycompany',
                linkChevron: true,
                text: true
              }
            }
          ]
        },
        {
          title: 'Наш RUTUBE-канал',
          desc: 'Обучающие видео по работе с сервисом',
          image: 'rutube.svg',
          mini: true,
          buttons: [
            {
              text: 'Открыть',
              native: this.onChannelOpen,
              props: {
                href: XWAY_RUTUBE_CHANNEL,
                linkChevron: true,
                text: true
              }
            }
          ]
        }
      ],
      tabs: [
        {
          id: 'all',
          title: 'Все продукты',
          to: {
            name: 'onboarding.products.all'
          },
          metrics: 'mmp_onboarding_products_all'
        }, {
          id: 'start',
          title: 'Запуск магазина',
          to: {
            name: 'onboarding.products.start'
          },
          metrics: 'mmp_onboarding_shop'
        }, {
          id: 'increase',
          title: 'Увеличение продаж',
          to: {
            name: 'onboarding.products.increase'
          },
          metrics: 'mmp_onboarding_sale'
        }, {
          id: 'scaling',
          title: 'Масштабирование бизнеса',
          to: {
            name: 'onboarding.products.scaling'
          },
          metrics: 'mmp_onboarding_business'
        }]
    }
  },

  computed: {
    isCardsListVisible () {
      return currentTheme.name === getDefaultThemeName()
    }
  },

  mounted () {
    const isCookie = getCookie('new_user')
    if (isCookie) {
      this.$modal.show('add-marketplace-modal', { source: 'promo_onboarding' })

      removeCookie('new_user', {
        path: '/',
        domain: process.env.VUE_APP_CURRENT_ROOT_DOMAIN
      })
    }
  },

  methods: {
    onChannelOpen () {
      this.$metrics.hit('mmp_onboarding_youtube')
    },

    onTelegramOpen () {
      this.$metrics.hit('mmp_onboarding_telegram')
    },

    gotToTariffsPage () {
      this.$router.push(ROUTES.TARIFFS.path)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .top-card
    height 100%
</style>
